<template>
  <v-container>
    <v-card v-if="currentPage === 'tours'">
      <v-card-title>
        Tour(s) Booking
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn color="primary" @click="clearFilters">Clear</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu
              ref="startMenu"
              v-model="startMenuVisible"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRange.start"
                  label="Departure date from"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange.start"
                @input="startMenuVisible = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="endMenu"
              v-model="endMenuVisible"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRange.end"
                  label="Return date to"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange.end"
                @input="endMenuVisible = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-data-table
          :headers="tourHeaders"
          :items="filteredTours"
          :search="search"
          :sort-by="['departure_date']"
          :sort-desc="[false]"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item.tour_group.tour_group_name="{ item }">
            {{ item.tour_group.tour_group_name }}
          </template>
          <template v-slot:item.tour_group.country.name="{ item }">
            {{ item.tour_group.country ? item.tour_group.country.name : 'N/A' }}
          </template>
          <template v-slot:item.departure_date="{ item }">
            {{ formatDate(item.departure_date) }}
          </template>
          <template v-slot:item.return_date="{ item }">
            {{ formatDate(item.return_date) }}
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="neededPassengersToGroup(item) > 0">{{ neededPassengersToGroup(item) }} TO G</span>
            <span v-else-if="availableSeats(item) > 0">G {{ availableSeats(item) }} seats left</span>
            <span v-else>Fully Booked</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="primary" @click.stop="viewTourDetails(item)">Book</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <v-card-actions>
        <v-btn color="primary" @click="currentPage = 'addTour'">Add Tour</v-btn>
      </v-card-actions> -->
    </v-card>
    <!-- 其他卡片（如 addTour, editTour 等）保持不变 -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 'tours',
      tours: [],
      search: '',
      dateRange: {
        start: null,
        end: null,
      },
      startMenuVisible: false,
      endMenuVisible: false,
      tourHeaders: [
        { text: 'Tour Name', value: 'tour_group.tour_group_name' },
        { text: 'Country', value: 'tour_group.country.name' }, // 新增的 country 列
        { text: 'Code', value: 'code' },
        { text: 'Departure Date', value: 'departure_date' },
        { text: 'Return Date', value: 'return_date' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      // 其他数据属性...
      itemsPerPage: 15
    };
  },
  computed: {
    filteredTours() {
      return this.tours.filter(tour => {
        const departureDate = new Date(tour.departure_date);
        const returnDate = new Date(tour.return_date);
        const matchesDateRange = (!this.dateRange.start || departureDate >= new Date(this.dateRange.start)) &&
                                (!this.dateRange.end || returnDate <= new Date(this.dateRange.end));
        const matchesSearch = !this.search || 
          tour.tour_group.tour_group_name.toLowerCase().includes(this.search.toLowerCase()) ||
          (tour.tour_group.country && tour.tour_group.country.name.toLowerCase().includes(this.search.toLowerCase())) ||
          tour.code.toLowerCase().includes(this.search.toLowerCase());
        return matchesDateRange && matchesSearch;
      });
    },
  },
  methods: {
    getTours() {
      axios.get('/api/tours')
        .then(response => {
          this.tours = response.data;
        })
        .catch(error => {
          console.error('Error fetching tours:', error);
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    },
    getTotalPassengers(tour) {
      return tour.bookings.reduce((total, booking) => total + booking.passengers.length, 0);
    },
    neededPassengersToGroup(tour) {
      const totalPassengers = this.getTotalPassengers(tour);
      return Math.max(0, tour.min_g - totalPassengers);
    },
    availableSeats(tour) {
      const totalPassengers = this.getTotalPassengers(tour);
      // 假设 tour.seat 存在并表示总座位数
      return Math.max(0, (tour.seat || 0) - totalPassengers);
    },
    viewTourDetails(tour) {
      this.$router.push({ name: 'TourDetail', params: { id: tour.id } });
    },
    clearFilters() {
      this.search = '';
      this.dateRange.start = null;
      this.dateRange.end = null;
    },
  },
  mounted() {
    this.getTours();
  },
};
</script>